<template>
  <div>
    <Header />
    <section class="bg-white">
      <div class="container py-4">
        <div class="card border-dark">
          <div class="card-header bg-dark text-white">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0 py-2">Top Nft Collections</h6>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-striped log-table-new mb-0">
                <thead>
                  <tr>
                    <td></td>
                    <td>Name</td>
                    <td class="text-nowrap">Floor Price</td>
                    <td class="text-nowrap">Average Price</td>
                    <td class="text-nowrap">Market Cap</td>
                    <td class="text-start">Volume</td>
                    <td class="text-wrap text-start">Traders</td>
                    <td class="text-start text-nowrap"># of Sales</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(nft, index) in nftLists.results"
                    :key="index"
                    class="align-middle"
                  >
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>
                      <div
                        class="
                          d-flex
                          justify-content-start
                          align-items-center
                          gap-3
                        "
                      >
                        <img
                          :src="nft.logo"
                          class="img-responsive rounded-10"
                          width="40px"
                          height="40px"
                          alt=""
                        />
                        <div class="d-flex flex-column justify-content-start">
                          <span v-if="nft.isPartial == false" class="fw-bold">
                            <router-link
                              class="text-decoration-none text-dark text-nowrap"
                              :to="
                                '/nft-collection/' +
                                nft.categoryKey +
                                '/' +
                                nft.platformKey +
                                '/' +
                                nft.linkKey +
                                '/' +
                                nft.logoKey
                              "
                              v-if="nft.platformKey != null"
                            >
                              {{ nft.name }}
                            </router-link>
                            <router-link
                              class="text-decoration-none text-dark text-nowrap"
                              :to="
                                '/nft-collection/' +
                                nft.categoryKey +
                                '/' +
                                nft.activeProtocols[0] +
                                '/' +
                                nft.linkKey +
                                '/' +
                                nft.logoKey
                              "
                              v-else
                            >
                              {{ nft.name }}
                            </router-link>
                          </span>
                          <span
                            v-else
                            class="fw-bold text-decoration-none text-dark"
                            >{{ nft.name }}</span
                          >
                          <div class="text-capitalize">
                            {{ nft.protocols[0] }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-column">
                        <div v-if="nft.floorPriceInFiat != null">
                          ${{ nft.floorPriceInFiat | formatNumber }}
                        </div>
                        <div v-else>-</div>
                        <div v-if="nft.floorPriceChange != null">
                          <div
                            v-if="nft.floorPriceChange > 0"
                            class="text-success"
                          >
                            {{ nft.floorPriceChange }}
                          </div>
                          <div v-else class="text-danger">
                            {{ nft.floorPriceChange }}
                          </div>
                        </div>
                        <div v-else>-</div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-column">
                        <div v-if="nft.avgPriceInFiat != null">
                          ${{ nft.avgPriceInFiat | formatNumber }}
                        </div>
                        <div v-else>-</div>
                        <div v-if="nft.avgPriceChange != null">
                          <div
                            v-if="nft.avgPriceChange > 0"
                            class="text-success"
                          >
                            {{ nft.avgPriceChange }}
                          </div>
                          <div v-else class="text-danger">
                            {{ nft.avgPriceChange }}
                          </div>
                        </div>
                        <div v-else>-</div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-column">
                        <div v-if="nft.marketCapInFiat != null">
                          ${{ nft.marketCapInFiat | formatNumber }}
                        </div>
                        <div v-else>-</div>
                        <div v-if="nft.marketCapChange != null">
                          <div
                            v-if="nft.marketCapChange > 0"
                            class="text-success"
                          >
                            {{ nft.marketCapChange }}
                          </div>
                          <div v-else class="text-danger">
                            {{ nft.marketCapChange }}
                          </div>
                        </div>
                        <div v-else>-</div>
                      </div>
                    </td>
                    <td class="text-start">
                      <div class="d-flex flex-column">
                        <div>${{ nft.volumeInFiat | formatNumber }}</div>
                        <div v-if="nft.volumeChange > 0" class="text-success">
                          {{ nft.volumeChange }}%
                        </div>
                        <div v-else class="text-danger">
                          {{ nft.volumeChange }}%
                        </div>
                      </div>
                    </td>
                    <td class="text-start">
                      <div class="d-flex flex-column">
                        <div>{{ nft.tradersCount }}</div>
                        <div
                          v-if="nft.tradersCountChange > 0"
                          class="text-success"
                        >
                          {{ nft.tradersCountChange }}%
                        </div>
                        <div v-else class="text-danger">
                          {{ nft.tradersCountChange }}%
                        </div>
                      </div>
                    </td>
                    <td class="text-start">
                      <div class="d-flex flex-column">
                        <div>{{ nft.salesCount }}</div>
                        <div
                          v-if="nft.salesCountChange > 0"
                          class="text-success"
                        >
                          {{ nft.salesCountChange }}%
                        </div>
                        <div v-else class="text-danger">
                          {{ nft.salesCountChange }}%
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row mt-3">
              <ul
                class="
                  pagination pagination-md
                  justify-content-center
                  text-center
                "
              >
                <li class="page-item" :class="page === 1 ? 'disabled' : ''">
                  <a
                    :class="
                      page === 1
                        ? 'page-link bg-outline-success border-success text-success'
                        : 'page-link bg-success border-success text-white'
                    "
                    @click="prevPage"
                  >
                    Previous
                  </a>
                </li>
                <li
                  class="
                    page-link
                    text-success
                    border-success border-start-0 border-end-0
                  "
                  style="background-color: inherit"
                >
                  {{ page }} of {{ lastPage }}
                </li>
                <li
                  class="page-item"
                  :class="page === lastPage ? 'disabled' : ''"
                >
                  <a
                    :class="
                      page === lastPage
                        ? 'page-link bg-outline-success border-success text-success'
                        : 'page-link bg-success border-success text-white'
                    "
                    @click="nextPage"
                  >
                    Next
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";

export default {
  name: "NftCollectionList",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      nftLists: [],
      nftPage: 1,
      page: 1,
      perPage: 25,
    };
  },
  mounted() {
    this.getNftList();
  },
  computed: {
    showRepos() {
      let start = (this.page - 1) * this.perPage;
      let end = start + this.perPage;
      return this.nftLists.results.slice(start, end);
    },
    lastPage() {
      let length = this.nftLists.pageCount;
      return length;
    },
  },
  methods: {
    getNftList() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/nft/v2.1/collection/day?limit=20&page=" +
            this.page +
            "&currency=USD&sort=volumeInFiat&order=desc"
        )
        .then((res) => {
          this.nftLists = res.data;
        });
    },
    prevPage() {
      this.page--;
      this.getNftList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    nextPage() {
      this.page++;
      if (this.page == this.lastPage - 1) {
        this.nftPage++;
        this.getNftList();
      }
      this.getNftList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style></style>
